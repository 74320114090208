import React from 'react';
import { Container, Spinner, Row, Col, Alert } from 'react-bootstrap';

import Banner from './Banner';
import ScheduleRow from './ScheduleRow';

import { Button } from 'react-bootstrap';
import WhatsOnNow from './WhatsOnNow';

const Schedule = (props) => {

  if ( !Array.isArray(props.schedule) || !props.schedule.length ) {
    return (
      <>
        <Banner
          headline="Loading..."
        />
        <Container>
          <Spinner animation="border" />
        </Container>
      </>
    );
  }

  const firstDay = new Date(2021,3,23);
  const today = new Date();

  // Long timezone (e.g. Europe/Dublin)
  
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const shortTimezone = 'Your time'

  let lastTimestamp = new Date(1);
  

  return (
    <>
      <Banner
        headline="To Uncrown A King"
        subhead="A 15th Century Symposium"
      />

      <Container>

        { today < firstDay
          ?
            (
              <Row>
                <Col>
                </Col>
      
                <Col xs={12} sm={10} md={8}>
      
                  <Alert variant="primary">
                    <p>
                      Greetings and welcome to our weekend of classes on various aspects of mid-Fifteenth century life, with a focus on the Wars of The Roses!
                    </p>
      
                    <p>
                      These civil wars have inspired art and literature for centuries, spawned the dramatic Tudor Dynasty and reverberated across Europe. We offer a chance to delve into the lives of the great and small, looking at the food they ate, the clothes and arts of the end of the Medieval and the dawning of the Renaissance.
                    </p>
      
                    <p>
                      With Eventbrite driving registration, classes on Zoom, and the discussions and socialising on Discord, we hope to provide a warm and friendly online experience.
                    </p>
      
                    <p>
                      Our team looks forward to meeting you on <b>Saturday 24th</b> and <b>Sunday 25th April</b>.
                    </p>
                  </Alert>
      
                  <br />
                </Col>
      
                <Col>
                </Col>
              
              </Row>
            )
          :
            (
              <WhatsOnNow schedByRoom={props.schedByRoom} />
            )
        }


        <Row>
          <Col xs={12} className="text-center">
            Event time is <b>{props.eventTimezone.longname}</b>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            Your timezone is <b>{timezone}</b>
          </Col>
        </Row>

        { 
          props.schedule.map( ( row, i ) => {

            const outputRow = <ScheduleRow
                                key={i}
                                row={row}
                                index={i}
                                lastTimestamp={lastTimestamp}
                                timezone={timezone}
                                shortTimezone={shortTimezone}
                                eventTimezone={props.eventTimezone}
                                roomnames={props.roomnames}
                              />;

            lastTimestamp = row.when;

            return outputRow;

          })
        }

        <Row>
          <Col><br /></Col>
        </Row>

        <Row>
          <Col className="text-center">
            <Button variant="primary" href="https://drive.google.com/file/d/1f8iqzcVY8oybD-g5Sx-VQDRZ70cN5Kzx/view"><b>Download the schedule grid (PDF)</b></Button>
          </Col>
        </Row>


      </Container>
      
    </>
  );


}

export default Schedule;


/*

*/