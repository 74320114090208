import React from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Icon from '@mdi/react';
import { mdiAlert } from '@mdi/js';

import heraldry from '../images/id-arms.svg';
import duninmara_arms from '../images/id-arms.svg';

import Ticker from './Ticker';

const Layout = (props) => {
  return (
    <>
      <Navbar expand="lg" bg="drachenwald" variant="dark">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                alt=""
                src={heraldry}
                width="40"
                className="d-inline-block align-middle"
              />{' '}
              To Uncrown A King
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ml-auto">
              <Nav.Link className="mx-2" href="https://www.eventbrite.co.uk/e/to-uncrown-a-king-tickets-138472578209">Register</Nav.Link>
              <LinkContainer to="/housekeeping"><Nav.Link className="mx-2">Housekeeping</Nav.Link></LinkContainer>
              <LinkContainer to="/classlist"><Nav.Link className="mx-2">Class list</Nav.Link></LinkContainer>
            </Nav>
            <Nav>
              <Nav.Item>&nbsp;&nbsp;&nbsp;</Nav.Item>
              <Nav.Item className="bg-drachenwald text-white text-left">
                <div style={{width: '145px', textAlign: 'right'}} >
                  <Ticker {...props} />
                </div>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      { props.flashmsg
        ?
          <div className="bg-warning">
            <Container>
              <div className="text-center">
                <Icon path={mdiAlert} title="Alert" />{' '}
                {props.flashmsg}
              </div>
            </Container>
          </div>
        :
          null
      }

      { props.children }

      <br />

      <Navbar expand="lg" bg="drachenwald" variant="dark">
        <Container>
          <Row>
            <Col xs="auto">
              <Navbar.Brand href="https://duninmara/">
                <img
                  alt=""
                  src={duninmara_arms}
                  width="90"
                  className="d-inline-block align-middle"
                />
              </Navbar.Brand>
            </Col>
            <Col className="align-middle">
              <Navbar.Text className="align-middle">
                <p>
                  &copy; 2021 Insulae Draconis. This is a subsite of the recognised web site for Insulae Draconis in the Society for Creative Anachronism.<br />
                  Copyright on all content and images remains with the creators.
                </p>
                <p>
                  <a href="https://insulaedraconis.org/">https://insulaedraconis.org/</a>
                </p>
              </Navbar.Text>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </>
  );
}

export default Layout;